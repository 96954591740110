define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="reviewable-filter reviewable-filter-endorsed-username-to-filter">
    <label class="filter-label">{{i18n "review.endorsed_username"}}</label>
    <EmailGroupUserChooser
      @value={{additionalFilters.endorsed_username}}
      @onChange={{action "updateEndorsedUsername"}}
      @autocomplete="off"
      @options={{hash fullWidthWrap=true maximum=1}}
    />
  </div>
  */
  {
    "id": "1x+pMCRw",
    "block": "[[[10,0],[14,0,\"reviewable-filter reviewable-filter-endorsed-username-to-filter\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"filter-label\"],[12],[1,[28,[35,2],[\"review.endorsed_username\"],null]],[13],[1,\"\\n  \"],[8,[39,3],null,[[\"@value\",\"@onChange\",\"@autocomplete\",\"@options\"],[[30,0,[\"additionalFilters\",\"endorsed_username\"]],[28,[37,4],[[30,0],\"updateEndorsedUsername\"],null],\"off\",[28,[37,5],null,[[\"fullWidthWrap\",\"maximum\"],[true,1]]]]],null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `additionalFilters` property path was used in the `discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.additionalFilters}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"div\",\"label\",\"i18n\",\"email-group-user-chooser\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});