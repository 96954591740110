define("discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _object, _service, _decorators, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if show}}
    <label class="checkbox-label is-category-expert-question">
      <Input
        @type="checkbox"
        @checked={{model.is_category_expert_question}}
        {{on "input" this.triggerAppEvent}}
      />
      {{i18n "category_experts.ask_category_expert"}}
    </label>
  {{/if}}
  */
  {
    "id": "Imx5A8pF",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"checkbox-label is-category-expert-question\"],[12],[1,\"\\n    \"],[8,[39,2],[[4,[38,3],[\"input\",[30,0,[\"triggerAppEvent\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"model\",\"is_category_expert_question\"]]]],null],[1,\"\\n    \"],[1,[28,[35,4],[\"category_experts.ask_category_expert\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `show` property path was used in the `discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.show}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"label\",\"input\",\"on\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    appEvents: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      if ((this.model.creatingTopic || this.model.editingFirstPost) && this.model.topic && this.model.topic.is_category_expert_question) {
        this.set("model.is_category_expert_question", true);
      }
    },
    show(model, category) {
      if (!category || !category.allowingCategoryExpertQuestions) {
        return false;
      }
      return model.editingFirstPost || model.creatingTopic;
    },
    triggerAppEvent(e) {
      this.appEvents.trigger("category-experts:is-question-checkbox-toggled", {
        checked: e.target.checked
      });
    }
  }, [["method", "show", [(0, _decorators.default)("model", "model.category")]], ["method", "triggerAppEvent", [_object.action]]])));
});