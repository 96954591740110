define("discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/paper-key", "discourse/plugins/discourse-encrypt/lib/protocol", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _ajax, _decorators, _discourse, _paperKey3, _protocol, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.generate}}
    @closeModal={{@closeModal}}
    @title={{i18n
      (if
        @model.device
        "encrypt.generate_paper_key.title_device"
        "encrypt.generate_paper_key.title"
      )
    }}
  >
    <:body>
      <p>
        {{i18n
          (if
            @model.device
            "encrypt.generate_paper_key.instructions_device"
            "encrypt.generate_paper_key.instructions"
          )
        }}
      </p>
      <p class="paper-key">{{this.paperKey}}</p>
    </:body>
  </DModal>
  */
  {
    "id": "fiY78T6C",
    "block": "[[[8,[39,0],[[4,[38,3],[[30,0,[\"generate\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[[52,[30,2,[\"device\"]],\"encrypt.generate_paper_key.title_device\",\"encrypt.generate_paper_key.title\"]],null]]],[[\"body\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[[52,[30,2,[\"device\"]],\"encrypt.generate_paper_key.instructions_device\",\"encrypt.generate_paper_key.instructions\"]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"paper-key\"],[12],[1,[30,0,[\"paperKey\"]]],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"if\",\"did-insert\",\":body\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key.hbs",
    "isStrictMode": false
  });
  class GeneratePaperKey extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "paperKey", [_tracking.tracked]))();
    #paperKey = (() => (dt7948.i(this, "paperKey"), void 0))();
    async generate() {
      const paperKey = (0, _paperKey3.generatePaperKey)();
      const label = this.args.model.device ? "device" : `paper_${paperKey.substr(0, paperKey.indexOf(" ")).toLowerCase()}`;
      const identity = await (0, _discourse.getIdentity)();
      const exported = await (0, _protocol.exportIdentity)(identity, paperKey);
      this.paperKey = paperKey;
      await (0, _ajax.ajax)("/encrypt/keys", {
        type: "PUT",
        data: {
          public: exported.public,
          private: exported.private,
          label
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "generate", [_decorators.bind]))();
  }
  _exports.default = GeneratePaperKey;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeneratePaperKey);
});